.progress-bar-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    flex-wrap: wrap;
}