.design-position .adm-list-item,
.design-position .adm-list-body {
    background: none;
    padding: 0;
}

.design-position .adm-list-item-content {
    background: none;
    border: none;
    padding: 0;
}

.design-position .adm-list-item-content-main {
    padding: 0;
}

.design-position .adm-collapse-panel-header .adm-list-item-content-main {
    padding: 0;
}

.design-position .adm-list-item.adm-collapse-panel-header:after {
    border: none !important;
}

.design-position .adm-list-item:active:not(.adm-list-item-disabled) {
    background: none !important;
}

.position-wrapper {
    transition: max-height 500ms ease-in;
    /*max-height: 4000px;*/
}

.hide-position {
    /* transition: max-height 400ms ease-out;
    max-height: 0px;
    overflow: hidden; */
    display: none;
}

.search-block .adm-search-bar {
    height: 36px !important;
}

.search-block .adm-search-bar .adm-search-bar-input-box {
    height: 100% !important;
    border-radius: 6px 0px 0px 6px !important;
}

.adm-default {
    --adm-color-primary: #ee3244ff !important;
    --adm-color-text: #333 !important;
    --adm-color-weak: #999 !important;
    --adm-color-light: #ccc !important;
}

body .adm-default {
    color: var(--adm-color-text);
}

.modal-product-tour .adm-modal-image-container {
    overflow-y: auto;
}

.max-width-content-layout {
    max-width: 500px;
}

@media (max-width: 280px) {
    .tab-bar-design-header .btn-publish-header {
        gap: 4px;
        font-size: 13px !important;
    }
}

.max-width-content-layout {
    max-width: 500px;
}

.expand-position-title .adm-divider-content {
    background-color: #00b578 !important;
}

.expand-position-title {
    border-color: #00b578 !important;
}

.expand-block-title {
    border: 0 !important;
    position: absolute !important;
    z-index: 2 !important;
    top: 0;
    left: 0;
    margin: 0 !important;
}

.expand-block-title .adm-divider-content {
    border: 1px solid #00b578 !important;
    border-radius: 0 0 4px 0px !important;
    background: rgba(0, 181, 120, 0.8) !important;
    font-size: 11px !important;
    padding: 4px 8px !important;
    font-weight: 400 !important
}

.modal-position-info .adm-modal-image-container {
    overflow: auto;
}

.modal-position-info .adm-space {
    display: none !important;
}

.position-info-content .position-info-icon {
    position: absolute;
    top: 8px;
    right: 8px;
}

.popover-manual .wrapper-image {
    height: 180px;
}

.popover-manual .img-placeholder {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.custom-text-area .adm-text-area-element {
    height: 181px !important;
}

;

.inactive-position {
    -webkit-filter: grayscale(75%) opacity(0.6);
    filter: grayscale(75%) opacity(0.6);
}

.design-content-class>header,
.design-content-class>main,
.design-content-class>footer {
    transition: 0.3s all ease;
}

.add-block-circle {
    width: 125%;
    height: 125%;
    top: -12.5%;
    left: -12.5%;
    position: absolute;
    background-color: transparent;
    border-radius: 100%;
    border: 1px solid rgb(238 50 68);
    -webkit-animation: animation-border-line 3s infinite ease-in-out;
    animation: animation-border-line 3s infinite ease-in-out;
    transition: all .5s;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 0.35;
}

@-webkit-keyframes animation-border-line {
    0% {
        -webkit-transform: rotate(0) scale(1) skew(0deg);
        transform: rotate(0) scale(1.15) skew(0deg);
        opacity: 0;
        border-width: 0px;
    }

    35% {
        -webkit-transform: rotate(0) scale(1) skew(0deg);
        transform: rotate(0) scale(1.2) skew(0deg);
        opacity: 0.15;
        border-width: 1px;
    }

    50% {
        -webkit-transform: rotate(0) scale(1.25) skew(0deg);
        transform: rotate(0) scale(1.2) skew(0deg);
        opacity: 0.25;
        border-width: 2px;
    }

    100% {
        -webkit-transform: rotate(0) scale(1.5) skew(0deg);
        transform: rotate(0) scale(1.4) skew(0deg);
        opacity: 0;
        border-width: 0px;
    }
}

.add-block-circle-fill {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgb(238 50 68);
    border-radius: 100%;
    border: 2px solid transparent;
    -webkit-animation: animation-fill 3s infinite ease-in-out;
    animation: animation-fill 3s infinite ease-in-out;
    transition: all .5s;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transition-delay: 1.5s;
}

@-webkit-keyframes animation-fill {
    0% {
        -webkit-transform: rotate(0) scale(1) skew(0deg);
        transform: rotate(0) scale(1) skew(0deg);
        opacity: 0;
    }

    50% {
        -webkit-transform: rotate(0) scale(1.25) skew(0deg);
        transform: rotate(0) scale(1.25) skew(0deg);
        opacity: 0.35;
    }

    100% {
        -webkit-transform: rotate(0) scale(1) skew(0deg);
        transform: rotate(0) scale(1) skew(0deg);
        opacity: 0;
    }
}

.inactive-position {
    -webkit-filter: grayscale(75%) opacity(0.6);
    filter: grayscale(75%) opacity(0.6);
}

.design-content-class>header,
.design-content-class>main,
.design-content-class>footer {
    transition: 0.3s all ease;
}

.button-anim-square-fill {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: #ee3244;
    border-radius: 6px;
    border: 2px solid transparent;
    -webkit-animation: animation-square-fill 3s ease-in-out infinite;
    animation: animation-square-fill 3s ease-in-out infinite;
    transition: all .5s;
    transform-origin: 50% 50%;
    transition-delay: 1.5s;
}

.button-anim-publish {
    -webkit-animation: animation-publish-square-fill 3s ease-in-out infinite;
    animation: animation-publish-square-fill 3s ease-in-out infinite;
}

@-webkit-keyframes animation-square-fill {
    0% {
        -webkit-transform: rotate(0) scale(1) skew(0deg);
        transform: rotate(0) scale(1) skew(0deg);
        opacity: 0;
    }

    50% {
        -webkit-transform: rotate(0) scale(1.25) skew(0deg);
        transform: rotate(0) scale(1.25) skew(0deg);
        opacity: 0.35;
    }

    100% {
        -webkit-transform: rotate(0) scale(1) skew(0deg);
        transform: rotate(0) scale(1) skew(0deg);
        opacity: 0;
    }
}

@-webkit-keyframes animation-publish-square-fill {
    0% {
        -webkit-transform: rotate(0) scale(1) skew(0deg);
        transform: rotate(0) scale(1) skew(0deg);
        opacity: 0;
    }

    50% {
        -webkit-transform: rotate(0) scale(1.25) skew(0deg);
        transform: rotate(0) scale(1.075, 1.25) skew(0deg);
        opacity: 0.35;
    }

    100% {
        -webkit-transform: rotate(0) scale(1) skew(0deg);
        transform: rotate(0) scale(1) skew(0deg);
        opacity: 0;
    }
}

.modal-position-info .adm-space {
    display: none !important;
}

.position-info-content .position-info-icon {
    position: absolute;
    top: 8px;
    right: 8px;
}

.expand-position-title .adm-divider-content {
    background-color: #00b578 !important;
}

.expand-position-title {
    border-color: #00b578 !important;
}

.expand-block-title {
    border: 0 !important;
    position: absolute !important;
    z-index: 2 !important;
    top: 0;
    left: 0;
    margin: 0 !important;
}

.expand-block-title .adm-divider-content {
    border: 1px solid #00b578 !important;
    border-radius: 0 0 4px 0px !important;
    background: rgba(0, 181, 120, 0.8) !important;
    font-size: 11px !important;
    padding: 4px 8px !important;
    font-weight: 400 !important
}

.add-block-circle {
    width: 125%;
    height: 125%;
    top: -12.5%;
    left: -12.5%;
    position: absolute;
    background-color: transparent;
    border-radius: 100%;
    border: 1px solid rgb(238 50 68);
    -webkit-animation: animation-border-line 3s infinite ease-in-out;
    animation: animation-border-line 3s infinite ease-in-out;
    transition: all .5s;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 0.35;
}

.publish-button-animating>svg {
    -webkit-animation: 1.6s ease-in-out 0s infinite normal none running icon-updown-anim;
    animation: 1.6s ease-in-out 0s infinite normal none running icon-updown-anim;
}

@-webkit-keyframes icon-updown-anim {
    0% {
        translate: 0 1.5px;
    }

    33% {
        translate: 0 0;
    }

    66% {
        translate: 0 -1.5px;
    }

    100% {
        translate: 0 1.5px;
    }
}

.icon-shake {
    -webkit-animation: 2.5s ease-in-out 0s infinite normal none running icon-shake-anim;
    animation: 2.5s ease-in-out 0s infinite normal none running icon-shake-anim;
}

@-webkit-keyframes icon-shake-anim {
    0% {
        -webkit-transform: rotate(0) scale(1) skew(0deg);
        transform: rotate(0) scale(1) skew(0deg);
    }

    10% {
        -webkit-transform: rotate(-18deg) scale(1) skew(0deg);
        transform: rotate(-18deg) scale(1) skew(0deg);
    }

    20% {
        -webkit-transform: rotate(18deg) scale(1) skew(0deg);
        transform: rotate(18deg) scale(1) skew(0deg);
    }

    30% {
        -webkit-transform: rotate(-18deg) scale(1) skew(0deg);
        transform: rotate(-18deg) scale(1) skew(0deg);
    }

    40% {
        -webkit-transform: rotate(18deg) scale(1) skew(0deg);
        transform: rotate(18deg) scale(1) skew(0deg);
    }

    50% {
        -webkit-transform: rotate(0) scale(1) skew(0deg);
        transform: rotate(0) scale(1) skew(0deg);
    }

    100% {
        -webkit-transform: rotate(0) scale(1) skew(0deg);
        transform: rotate(0) scale(1) skew(0deg);
    }
}

.custom-text-area .adm-text-area-element {
    height: 181px !important;
}

.custom-modal-alert .adm-modal-footer {
    border-top: none;
}

.block-content-wrapper {
    /* max-height: 274px; */
    overflow: hidden;
}

.btn-view-more-text {
    width: 100%;
    padding: 8px 0px;
    color: var(--text-color-6);
    display: flex;
    line-height: 150%;
    font-size: 13px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-weight: 500;
}

.clip-content-text {
    border-radius: 0px 0px 6px 6px;
    padding: 8px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 79px;
}

.mask-image {
    -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
}

.btn-view-more-text svg path {
    fill: var(--text-color-6)
}

.popup-wrapper-block-text {
    position: relative;
    padding: 12px 20px;
    background: #fff;
    min-height: 100%;
}

.full-popup-block-text .full-screen-popup-content {
    background: rgb(245 245 245);
}

.popup-wrapper-block-text .text-content {
    line-height: var(--paragraph-line-height);
    font-weight: var(--paragraph-font-weight);
    font-family: var(--paragraph-font-family);
    font-size: var(--paragraph-font-size);
}

.popup-wrapper-block-text .text-title,
.popup-wrapper-block-text .text-content,
.popup-wrapper-block-text .text-content * {
    color: #333333 !important;
}

.popup-wrapper-block-text .text-content,
.popup-wrapper-block-text .text-content * {
    background: #fff !important;
}

.popup-wrapper-block-text .block-heading-class {
    width: calc(100% - 22px);
}

.popup-wrapper-block-text .text-title {
    line-height: var(--heading-line-height);
    font-weight: var(--heading-font-weight);
    font-family: var(--heading-font-family);
}

.custom-modal-alert .adm-modal-footer {
    border-top: none;
}

.modal-brand-gift-golive .adm-modal-image-container {
    overflow: hidden;
}

.modal-brand-gift-golive .adm-space {
    display: none !important;
}
.modal-brand-gift-golive .adm-plain-anchor {
    color: black;
}

.list-img-introduction-brand-gift img {
    /* margin-bottom: -1px; */
}

.custom-gap{
    gap: 12px !important;
}

.overwrite-text-template{
    background-color: var(--adm-color-white) !important;
    color: var(--adm-color-primary) !important;
}
.adm-checkbox .adm-checkbox-icon{
    border-radius: 4px !important;
}
.custom-gap {
    gap: 12px !important;
}

.box-icon .adm-tabs-header{
    border-bottom: solid 1px var(--adm-color-border) !important;
}

.box-icon .adm-search-bar{

    width: calc(100% - 82px);
}
.box-icon .adm-tabs-header{
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 36px;
    z-index: 9;
}
.box-icon .button-filter{
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid rgba(255, 255, 255, 1e-05);
    height: 36px;
}
.search-bar-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9;
}
.button-filter .button-title{
    font-size: 17px;
    line-height: 140%;
}

.box-icon .adm-tabs-content{
    padding-top: 12px !important;
}

.adm-checkbox .adm-checkbox-icon{
    border-radius: 4px !important;
}

.overwrite-text-template{
    background-color: var(--adm-color-white) !important;
    color: var(--adm-color-primary) !important;
}

.result-empty .title{
    font-weight: 500;
    font-size: 15.6px;
    line-height: 130%;
    color: #171717;
}

.result-empty .sub-title{
    line-height: 130%;
    color: #737373;
}
.text-primary{
    color: var(--adm-color-primary) !important;
}
.custom-button-confirm-modal-alert .adm-modal-footer .adm-space-item button {
    background-color: #FA8C16;
    border: 1px #FA8C16;
    border-radius: 4px;
    padding: 7px 12px;
    color: white !important;
}

.btn-flex{
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 8px
}
.rating-star{
    width: 100%;
    justify-content: space-between;
}

.rating-star .adm-rate-box {
    width: 20%;
}
.rating-star .adm-rate-star{
    display: flex;
    justify-content: center;
}
.rating-popup-content{
    border-top: 1px solid var(--adm-color-border)
}
.rating-popup-content .form-item{
    padding: 12px 0px;
    border-bottom: 1px solid var(--adm-color-border);
}
.title-star{
    text-align: center;
    font-size: 15px;
    line-height: 131%;
    min-height: 20px;
    margin-top: 8px;
}
.selector-category-star.adm-selector .adm-space.adm-space {
    -moz-column-gap: 12px;
    column-gap: 12px;
}
.rating-popup-content .form-item.category{
    padding: 12px 16px;
}
.adm-rate-star.adm-rate-star-active svg path{
    fill: var(--active-color);
}
.rating-popup-content .form-item.input-text-box{
    border-bottom: none
}

.popup-auto .rating-popup-content .form-item {
    border-bottom: none;
}

.popup-auto .title-star {
    margin-top: 24px;
}
.swiperPremium .swiper-pagination{
    bottom: 0px !important;
}
.wrapper-setting{
    padding: 12px 0 0;    
}

@media (max-width: 767px){
    .wrapper-setting{
        padding-left: 12px;
        padding-right: 12px;
    }
}
